import React, {ChangeEvent, useMemo} from "react";
import _ from "lodash";
import {useAppSelector} from "redux/hooks";
import {useTranslation} from "react-i18next";
import StandaloneDropdownWithSearch, {
    DropdownWithSearchOption
} from "../../Input/DropdownWithSearch/StandaloneDropdownWithSearch";


type Props = {
    handleSubmitFilterUpdate: _.DebouncedFunc<() => any>;
    handleFilterInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const ProviderOfferFilter = ({handleSubmitFilterUpdate, handleFilterInputChange}: Props) => {
    const {t} = useTranslation();
    const providerValue = useAppSelector(state => state.hotelSearchResults.stateFilters.offerProviderFilter);
    const possibleProviders = useAppSelector(state => state.hotelSearchResults.allProvidersWithOffers);

    const providersFormed = useMemo(() => {
        if (!possibleProviders) {
            return [];
        }

        return possibleProviders
            .sort((a, b) => a.providerName > b.providerName ? 1 : -1)
            .map(provider =>
                new DropdownWithSearchOption(provider.providerName, provider.providerID)
            );
    }, [possibleProviders]);

    return (

        <StandaloneDropdownWithSearch
            inputProps={{
                required: false,
                name: "offerProviderFilter",
                value: providerValue,
                placeholder: t("hsb_sb_provider"),
                // onFocus: (e) => { },
                onChange: (e) => {
                    handleFilterInputChange(e);
                    handleSubmitFilterUpdate();
                }
            }}
            onValueChange={e => {
                const event = {
                    target: {
                        value: e.value,
                        name: "offerProviderFilter"
                    }
                };

                handleFilterInputChange(event as React.ChangeEvent<HTMLInputElement>);
                handleSubmitFilterUpdate();
            }}
            options={providersFormed}
            currentValue={providerValue}
        />

    )

};

export default ProviderOfferFilter;