import {ReduxMyBookingsPropTypes} from "proptypes/PropTypeRedux";
import {
    BookingSearchOptionsPropTypes
} from "proptypes/PropTypeObjects";
import MyBookingsTypes from "../constants/myBookings.constants";
import MyBookingsActionProps from "./actions/myBookings.reducer.actions";

export const INITIAL_STATE = {
    data: {
        bookings: [],
        bookingsCount: 0
    },
    quickFilters: [
        {
            value: "RECENT",
            label: "mb_smb_qf_recent_booking",
            tooltipText: "mb_smb_qf_recent_booking_tooltip"
        },
        {
            value: "CLOSE_ARRIVAL",
            label: "mb_smb_qf_close_arrival",
            tooltipText: "mb_smb_qf_close_arrival_tooltip"
        },
        {
            value: "PAYMENT_PENDING",
            label: "mb_smb_qf_payment_pending",
            tooltipText: "mb_smb_qf_payment_pending_tooltip"
        }
    ],
    bookingOptions: undefined,
    formData: {
        agentReference: "",
        autoCancel: undefined,
        bookingReference: "",
        createDateFrom: undefined,
        createDateTo: undefined,
        cxlDateFrom: undefined,
        cxlDateTo: undefined,
        destinationCountryId: 0,
        invoiceDateFrom: undefined,
        invoiceDateTo: undefined,
        invoiceNumber: "",
        paxName: "",
        paymentDeadlineFrom: undefined,
        paymentDeadlineTo: "",
        paymentStatuses: [],
        statuses: [],
        types: [],
        serviceEndDateFrom: undefined,
        serviceEndDateTo: undefined,
        serviceStartDateFrom: undefined,
        serviceStartDateTo: undefined,
        textSearch: "",
        userId: undefined,
        subAgentId: undefined,

        sort: undefined,
        franchiseeBookings: undefined
    },
    pageSize: 25,
    pageNr: 1,
    isSearching: false,
    optionsLoading: false,
    activeQuickFilters: [],
    advancedFilters: false,
    searchSubmitted: false,
    error: undefined
} as ReduxMyBookingsPropTypes;

export default (state = INITIAL_STATE, action: MyBookingsActionProps): ReduxMyBookingsPropTypes => {
    switch (action.type) {
    case MyBookingsTypes.RESET_DATA:
        return INITIAL_STATE;
    case MyBookingsTypes.START_SEARCH:
        return {
            ...state,
            isSearching: true,
            searchSubmitted: true
        };
    case MyBookingsTypes.SEARCH_SUCCESS:
        return {
            ...state,
            isSearching: false,
            data: action.data
        };
    case MyBookingsTypes.SEARCH_FAILURE:
        return {
            ...state,
            isSearching: false,
            error: action.error
        };
    case MyBookingsTypes.REQ_BOOKING_OPTIONS:
        return {
            ...state,
            optionsLoading: true
        };
    case MyBookingsTypes.GET_BOOKING_SEARCH_OPTIONS_SUCCESS:
        return {
            ...state,
            bookingOptions: action.bookingOptions,
            optionsLoading: false
        };
    case MyBookingsTypes.GET_BOOKING_OPTIONS_FAILURE:
        return {
            ...state,
            error: action.error,
            optionsLoading: false
        };
    case MyBookingsTypes.TOGGLE_ADVANCED_FILTERS:
        return {
            ...state,
            advancedFilters: !state.advancedFilters
        };
    case MyBookingsTypes.HANDLE_FORM_DATA:
        return {
            ...state,
            formData: action.formData,
            pageNr: 1,
            searchSubmitted: false
        };
    case MyBookingsTypes.HANDLE_SORT:
        return {
            ...state,
            searchSubmitted: false,
            formData: {
                ...state.formData,
                sort: action.sort
            }
        };
    case MyBookingsTypes.HANDLE_PAGE_NR:
        return {
            ...state,
            searchSubmitted: false,
            pageNr: action.pageNr
        };
    case MyBookingsTypes.HANDLE_PAGE_SIZE:
        return {
            ...state,
            searchSubmitted: false,
            pageSize: action.pageSize
        };
    case MyBookingsTypes.HANDLE_QUICK_FILTERS:
        return {
            ...state,
            searchSubmitted: false,
            activeQuickFilters: action.isActive
                ? [...state.activeQuickFilters, action.quickFilter]
                : state.activeQuickFilters.filter((qf) => action.quickFilter !== qf)
        };
    case MyBookingsTypes.MODIFY_COL_REQUEST:
        return {
            ...state,
            optionsLoading: true
        };
    case MyBookingsTypes.MODIFY_COL_FAILED:
        return {
            ...state,
            optionsLoading: false,
            error: action.error
        };
    case MyBookingsTypes.MODIFY_COL_SUCCESS: {
        const columns = state.bookingOptions.columns.map((col: any) => ({
            ...col,
            enabled: action.columnsObj[col.key]
        }));

        return {
            ...state,
            optionsLoading: false,
            error: undefined,
            bookingOptions: {
                ...state.bookingOptions,
                columns
            } as BookingSearchOptionsPropTypes
        };
    }
    case MyBookingsTypes.RESET_FORM_DATA:
        return {
            ...state,
            formData: {
                ...INITIAL_STATE.formData,
                sort: state.formData.sort
            },
            pageNr: INITIAL_STATE.pageNr,
            activeQuickFilters: [],
            searchSubmitted: false
        };
    default:
        return state;
    }
};
