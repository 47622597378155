import {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "redux/hooks";
import Tippy from "components/common/Tippy";
import {ReactComponent as FacebookIcon} from "@hotelston_web_frontend_assets/icons/social_media_icons/facebook.svg";
import {ReactComponent as LinkedinIcon} from "@hotelston_web_frontend_assets/icons/social_media_icons/linkedin.svg"
import {ReactComponent as InstagramIcon} from "@hotelston_web_frontend_assets/icons/social_media_icons/instagram.svg"
import {ReactComponent as YoutubeIcon} from "@hotelston_web_frontend_assets/icons/social_media_icons/youtube.svg"
// import {ReactComponent as MailIcon} from "@hotelston_web_frontend_assets/icons/social_media_icons/mail.svg"
import styles from "./SocialLinks.module.scss"


function SocialLinks(): ReactElement {
    const {t} = useTranslation();
    const ownUser = useAppSelector((state) => state.auth.userData?.companyUser.ownUser);

    return (
        <>
            {ownUser && (
                <div className={styles.root}>
                    <div className={styles.innerContainer}>
                        <Tippy content={t("sl_sl_follow_us", {platform: "Facebook"})}>
                            <a className={styles.fb} href="https://www.facebook.com/hotelston/" target="_blank"><FacebookIcon/></a>
                        </Tippy>
                        <Tippy content={t("sl_sl_follow_us", {platform: "Linkedin"})}>
                            <a
                                className={styles.li} href="https://linkedin.com/company/hotelston"
                                target="_blank"
                            ><LinkedinIcon/>
                            </a>
                        </Tippy>
                        <Tippy content={t("sl_sl_follow_us", {platform: "Instagram"})}>
                            <a
                                className={styles.ig} href="https://www.instagram.com/hotelston_official/"
                                target="_blank"
                            ><InstagramIcon/>
                            </a>
                        </Tippy>
                        <Tippy content={t("sl_sl_youtube")}>
                            <a
                                className={styles.yt} href="https://www.youtube.com/@hotelston_official"
                                target="_blank"
                            ><YoutubeIcon/>
                            </a>
                        </Tippy>
                        {/*<Tippy content={t("sl_sl_mail")}>*/}
                        {/*<a className={styles.ml} href="" target="_blank"><MailIcon/></a>*/}
                        {/*</Tippy>*/}
                    </div>
                </div>)}
        </>
    );
}

export default SocialLinks;