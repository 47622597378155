import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import UserButton from "components/base/UserButton";
import {UserButtonVariant} from "components/base/UserButton/UserButton";
import MainContainer from "components/base/MainContainer";
import {useAppSelector} from "redux/hooks";
import {RootState} from "redux/store/store.init";
import styles from "./NotVerifiedAgreement.module.scss";

type PayloadType = {
    bookingCount: number;
};

const NotVerifiedAgreement = (): ReactElement => {
    const {t} = useTranslation();
    const payload = useAppSelector(
        (state: RootState) =>
            state.actionRedirect?.actionRedirectPayload as PayloadType
    );

    return (
        <MainContainer
            containerClassName={styles.Root}
            variants={{
                center: true
            }}
        >
            <div className={styles.Container}>
                <div className={styles.Content}>
                    <h1>{t("a_r_not_verified_agreement_header")}</h1>

                    <div
                        className={styles.Explanation}
                        dangerouslySetInnerHTML={{
                            __html: t("a_r_not_verified_agreement_explanation", {bookingCount: payload?.bookingCount ? payload.bookingCount : "(?)"})
                        }}
                    />

                    <div className={styles.ButtonContainer}>
                        <UserButton
                            text={t("a_r_not_verified_agreement_button_text")}
                            variant={UserButtonVariant.PRIMARY}
                            buttonProps={{
                                onClick: () => history.back()
                            }}
                        />

                    </div>
                </div>
            </div>
        </MainContainer>
    )
}

export default NotVerifiedAgreement
