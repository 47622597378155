import {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "redux/hooks";
import cx from "classnames";
import {RoomDescriptionPropTypes} from "proptypes/PropTypeObjects";
import {ReactComponent as NoPhoto} from "assets/images/no-photo-placeholder.svg";
import Modal from "components/common/Modal";
import ModalWithOverlay from "components/common/Modal/ModalWithOverlay";
import RoomMoreInfo from "components/common/RoomMoreInfo/RoomMoreInfo";
import useKeyDetect, {KeyDetectCode} from "components/utils/useKeyDetect";
import styles from "./RoomMoreInfoModal.module.scss";

type props = {
    data: RoomDescriptionPropTypes
}

function RoomMoreInfoModal ({data}: props) {
    const {t} = useTranslation();
    const ownUser = useAppSelector((state) => state.auth.userData?.companyUser.ownUser);
    const [modalVisible, setModalVisible] = useState(false);

    const openModal = useCallback(() => {
        setModalVisible(true);
    }, [])

    const closeModal = useCallback(() => {
        setModalVisible(false);
    }, []);

    useKeyDetect(KeyDetectCode.ESCAPE, closeModal, true);

    const hasAllInDescription = data.provider === 1020;

    return (
        <>
            <div
                onClick={openModal}
                className={cx(styles.RoomMoreInfoModalContainer, (!data.name && !data.size && data.photos) && styles.Border)}
            >
                <div className={styles.ImageContainer}>
                    {data.photos && data.photos.length > 0 ? (<img src={data.photos[0].standardUrl} alt="Room picture"/>) : (<NoPhoto />)}
                </div>
                <div className={styles.TextContainer}>
                    <h5>{data.name} {(ownUser && data.provider) && <span>({data.provider})</span>}</h5>
                    {!!data.size && <p>{t("rmi_rmim_room_size")}<span className={styles.RoomSizeText}> {data.size} m2</span></p>}
                </div>
            </div>
            {modalVisible && (
                <Modal>
                    <ModalWithOverlay >
                        <div className={styles.ModalInnerContainer} onClick={closeModal}>
                            <div onClick={(e) => e.stopPropagation()}>
                                <RoomMoreInfo data={data} closeCallback={closeModal} fullInfoInDescription={hasAllInDescription} />
                            </div>
                        </div>
                    </ModalWithOverlay>
                </Modal>
            )}
        </>
    );

}
export {RoomMoreInfoModal};