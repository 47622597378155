import React, {ReactElement, useMemo} from "react";
import {useTranslation} from "react-i18next";
import Pricing, {PricingElement} from "@hotelston_web_frontend_components/components/special/pricing/Pricing";
import usePaymentOptions from "components/base/Payment/PaymentType/usePaymentOptions";
import {useAppSelector} from "../../../../../../redux/hooks";
import styles from "./SplitPricePricing.module.scss";
import {WebSplitPricePartStatus} from "../../../../../../proptypes/PropTypeObjects";
import cx from "classnames";
import {paymentPendingAfterConversionsSelector} from "../../../../../../redux/selectors/payment.selector";

export type SplitPricePricingProps = {
    className?: string;
};

const SplitPricePricing = ({className}: SplitPricePricingProps): ReactElement | null => {
    const {t} = useTranslation();

    const {
        paymentOptions
    } = usePaymentOptions();

    const resumedSession = useAppSelector((state) => state.payment.resumedSplitPriceSession);
    const splitPriceParts = useAppSelector((state) => state.payment.splitPriceParts);
    const transactionFee = useAppSelector((state) => state.pricing.displayTransactionFee);
    const paymentPendingAfterConversions = useAppSelector((state) => paymentPendingAfterConversionsSelector(state.payment));

    const items = useMemo(() => {
        const returnedItems: PricingElement[] = [];

        returnedItems.push({
            label: t("pc_pc_split_payment_pricing_booking_price"),
            amount: paymentOptions.originalAmount || paymentOptions.salePrice
        });

        if (resumedSession) {
            const totalTransactionFees = splitPriceParts.map((part) => part.transactionFee)
                .reduce((a, b) => a + b);
            const alreadyPaid = splitPriceParts.filter((part) => part.status === WebSplitPricePartStatus.SUCCESS)
                .map((part) => part.amount + part.transactionFee)
                .reduce((a, b) => a + b, 0)

            returnedItems.push(
                {
                    label: t("pc_pc_split_payment_pricing_transaction_fees"),
                    amount: totalTransactionFees || 0
                },
                {
                    label: t("pc_pc_split_payment_pricing_total"),
                    amount: paymentOptions.paymentPending + (totalTransactionFees || 0),
                    additionalSpaceBelow: true
                },
                {
                    label: t("pc_pc_split_payment_pricing_already_paid"),
                    amount: alreadyPaid
                },
                {
                    label: t("pc_pc_split_payment_pricing_payment_pending"),
                    amount: (paymentOptions.paymentPending + totalTransactionFees) - alreadyPaid
                }
            );

            return returnedItems;
        }

        returnedItems.push(
            {
                label: t("pc_pc_split_payment_pricing_transaction_fees"),
                amount: transactionFee,
                additionalSpaceBelow: true
            },
            {
                label: t("pc_pc_split_payment_pricing_total"),
                amount: paymentPendingAfterConversions + (transactionFee || 0)
            }
        );

        return returnedItems;
    }, [paymentOptions, paymentOptions, paymentPendingAfterConversions, resumedSession, splitPriceParts, t, transactionFee]);

    if (!paymentOptions) {
        return null;
    }

    return (
        <Pricing
            className={cx(styles.Root, className)}
            items={items}
        />
    );
}

export default SplitPricePricing;