import {Fragment, useMemo} from "react";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import {ReactComponent as Check} from "assets/icons/check.svg";
import getBedOptionsIcon from "utils/room/getBedOptionsIcon";
import getRoomOptionsIcon from "utils/room/getRoomTypeIcon";
import getAttributeIcon from "utils/room/getAttributeIcon";
import {RoomDescriptionPhotoTypes, RoomDescriptionPropTypes} from "proptypes/PropTypeObjects";
import {LazyImageSlider, LazyImageSliderImage} from "components/common/LazyImageSlider/LazyImageSlider";
import {ReactComponent as NoPhoto} from "../../../assets/images/no-photo-placeholder.svg";
import {ReactComponent as CrossIcon} from "../../../assets/icons/cross.svg";
import styles from "./RoomMoreInfo.module.scss";

type Props = {
    data: RoomDescriptionPropTypes;
    closeCallback?: () => void;
    fullInfoInDescription?: boolean;
}

function RoomMoreInfo({data, closeCallback, fullInfoInDescription}: Props) {
    const {t} = useTranslation();

    const imageArr = useMemo(() => {
        let ret: LazyImageSliderImage[] = [];
        if (data.photos && data.photos.length !== 0) {
            ret = data.photos.map((image: RoomDescriptionPhotoTypes) =>
                ({
                    original: image.standardUrl,
                    thumbnail: image.thumbnailUrl || image.standardUrl
                })) as LazyImageSliderImage[];
        }

        return ret;
    }, [data.photos]);

    const removeHtmlTags = (text: string) => text.replace(/<\/?[^>]*?>/g, "");

    return (
        <div className={cx(styles.Root)}>
            {closeCallback &&
                <div className={styles.CloseIconContainer}>
                    <CrossIcon
                        className={styles.CloseIcon}
                        aria-label="Close"
                        role="button"
                        onClick={closeCallback}
                    />
                </div>}
            <div className={styles.MainContainer}>
                <div
                    className={cx(styles.ImageGalleryContainer)}
                >
                    { data.photos && data.photos.length !== 0
                        ? (<LazyImageSlider
                                className={styles.ImageGalleryAdditionalClass}
                                images={imageArr}
                                showFullscreenButton={false}
                                showImageMessage={false}
                                showThumbnails={true}
                        />)
                        : (<NoPhoto />) }
                </div>

                {(data.name || data.description || data.bedOptions || data.attributes) &&
                    (<div className={cx(styles.DescriptionsContainer, fullInfoInDescription && styles.OnlyDescription)}>
                        {data.name && <h4>{data.name}</h4>}

                        {/*TODO for testing with tags in description*/}
                        {data.description && (
                            fullInfoInDescription
                                ? <div dangerouslySetInnerHTML={{__html: data.description}}/>
                                : <p>{removeHtmlTags(data.description)}</p>
                        )}

                        {!fullInfoInDescription && (data.size || data.attributes) &&
                            <div className={styles.RoomDetails}>
                                {!!data.size && <p>🏠 {t("rmi_rmi_room_size")}: <span>{data.size} m2</span></p>}
                                <div className={styles.Attributes}>
                                    {(data.attributes?.length > 0 &&
                                    data.attributes.map((attribute, index) => (
                                        <Fragment key={attribute + index}>
                                            {getAttributeIcon(attribute)}
                                            {/*TODO remove text and leave only img when more info of possible attributes is collected*/}
                                            <p>{attribute.replace("_", " ")}</p>
                                        </Fragment>)
                                    ))}
                                </div>
                            </div>}

                        {!fullInfoInDescription && data.roomCounts &&
                            <div className={styles.RoomsCount}>
                                {(Object.entries(data.roomCounts).map(([key, value]) => (
                                    +value !== 0 && (
                                        <div className={styles.RoomsCountInnerContainer} key={key}>
                                            {getRoomOptionsIcon(key)}
                                            <p>{key}</p>
                                            <span>{value}</span>
                                        </div>))))}
                            </div>}

                        {!fullInfoInDescription && data.bedOptions &&
                            <div className={styles.BedsOptions}>
                                {(Object.entries(data.bedOptions).map(([bedNr, bedText]) => (
                                    <div key={bedNr} className={styles.BedOptionsInnerContainer}>
                                        {getBedOptionsIcon(bedNr)}
                                        <p>{bedText}</p>
                                    </div>)
                                ))}
                            </div>}

                    </div>)}

            </div>

            <div className={styles.FacilitiesContainer}>
                {data.facilities && (Object.entries(data.facilities).map(([nr, text]) => (
                    <div className={styles.InnerContainer}>
                        <Check/><p key={nr}>{text}</p>
                    </div>)
                ))}
            </div>

            {(!data || Object.keys(data).length === 0) && (<h4 className={styles.NoData}>{t("rmi_rmi_no_data")}</h4>)}

        </div>
    )
}

export default RoomMoreInfo;