import React, {useState} from "react"
import AbstractCustomLoadingScreen from "../AbstractCustomLoadingScreen"
import styles from "./ChristmasScreen.module.scss"
import {ReactComponent as Corner} from "assets/icons/banner/christmas/corner.svg"
import {ReactComponent as BottomLeft} from "assets/icons/banner/christmas/bottomLeft.svg";
import {ReactComponent as BottomRight} from "assets/icons/banner/christmas/bottomRight.svg"
import {ReactComponent as TopLeft} from "assets/icons/banner/christmas/topLeft.svg"
import {ReactComponent as TopRight} from "assets/icons/banner/christmas/topRight.svg"
import cx from "classnames"
import _ from "lodash"
import LoadingScreenPhrase from "../../LoadingScreenPhrase/LoadingScreenPhrase"
import {useTranslation} from "react-i18next"
import {useLocale} from "../../../../../../components/utils/withLocalesAndCurrencies"

type ChristmasScreenProps = {
  phrase: string;
}

const ChristmasScreen = (props: ChristmasScreenProps) => {
    const {phrase} = props;

    const {t} = useTranslation()
    const locale = useLocale()
    const [uniqId] = useState(_.uniqueId("christmasBanner"));

    return (
        <AbstractCustomLoadingScreen
            currentDestination={phrase}
            className={styles.Root}
            noMessage
        >
            <div className={styles.BannerRoot}>
                <div className={styles.InnerContent}>
                    <div className={cx(styles.CenterLogo, locale === "pl" && styles.LogoLowerFont)}>
                        {/*<img src={AnimatedLogo} alt="animated-logo-spinner" />*/}

                        <svg viewBox="0 0 60 60">
                            <path
                                fill-rule="evenodd" clip-rule="evenodd" strokeWidth="10px"
                                d="M47.9358 22.7736C47.9358 19.5933 46.4853 16.5388 43.9213 14.5469C40.352 11.7753 35.9392 10.2501 31.4003 10.2501C28.6351 10.2501 25.8231 10.8171 23.136 12.013C15.5256 15.3997 11 22.882 11 30.7249V30.7261V30.7295C11 33.9081 11.7446 37.1475 13.3229 40.1893C15.732 44.8356 19.7644 48.1904 24.3911 49.8919C24.4986 49.9307 24.6091 49.9493 24.7177 49.9493C25.1057 49.9493 25.4693 49.7098 25.611 49.3237C25.6503 49.2165 25.6693 49.1052 25.6693 48.9962C25.6693 48.6078 25.4306 48.2426 25.0455 48.1011C20.8385 46.5539 17.1883 43.5133 15.0093 39.3104C13.5732 36.5411 12.9015 33.609 12.9015 30.7272C12.9026 23.613 17.0033 16.8252 23.9072 13.7556C26.3486 12.6693 28.8935 12.1568 31.4003 12.1568C35.5137 12.1562 39.5212 13.5406 42.757 16.0536C44.8481 17.6774 46.035 20.1736 46.035 22.7736C46.035 23.2971 45.987 23.824 45.8881 24.3504C45.2857 27.5701 42.9027 30.1266 39.8062 30.9829V20.7829C39.8062 20.2565 39.3813 19.8298 38.8564 19.8298C38.3314 19.8298 37.9054 20.2565 37.9054 20.7829V31.3377L25.0184 33.3655V20.7829C25.0178 20.2565 24.5929 19.8298 24.0679 19.8298C23.543 19.8298 23.1175 20.2565 23.1169 20.7829V33.6646L19.2394 34.2745C18.7706 34.3487 18.4364 34.7539 18.4364 35.2153C18.4364 35.264 18.4393 35.3139 18.4474 35.3637C18.5214 35.8339 18.9249 36.1695 19.3857 36.1695C19.4348 36.1695 19.484 36.1655 19.5337 36.1579L23.1169 35.5945V42.5875C23.1175 43.1133 23.5424 43.5411 24.0679 43.5411C24.5929 43.5411 25.0178 43.1139 25.0184 42.5875V35.2948L37.9054 33.2675V42.5875C37.9059 43.1139 38.3314 43.5411 38.8564 43.5411C39.3813 43.5411 39.8062 43.1133 39.8062 42.5875V32.9446C43.8404 32.0449 46.9854 28.8171 47.7566 24.7023C47.8763 24.0588 47.9358 23.4142 47.9358 22.7736Z
                                 M44.0326 45C43.824 44.9994 43.6107 45.0661 43.4344 45.2018H43.4338C42.1079 46.22 40.6157 47.0813 38.9701 47.7455C36.6534 48.6815 34.2326 49.1256 31.8354 49.1262C30.9197 49.1262 30.007 49.0606 29.1061 48.9341C29.0585 48.9278 29.0114 48.9244 28.9644 48.9244C28.4925 48.9244 28.0805 49.2601 28.01 49.7259C28.0035 49.7709 28 49.8165 28 49.8616C28 50.3205 28.3462 50.7195 28.8269 50.7885C29.8195 50.9287 30.8251 51 31.8354 51H31.8407C34.4824 51 37.1571 50.5092 39.7124 49.4768C41.5225 48.7448 43.1693 47.7956 44.6333 46.6726C44.8742 46.4873 45 46.2125 45 45.9366C45 45.7331 44.9312 45.5273 44.7914 45.3557C44.6009 45.1226 44.3171 44.9994 44.0326 45Z
                                 M49.4076 39.1323C49.2212 39.0435 49.0153 39 48.8138 39C48.4041 39 48.0053 39.1752 47.785 39.4886L48.001 39.5916L47.785 39.4886C47.0676 40.5104 46.2325 41.4786 45.289 42.3806C45.0961 42.5653 45 42.7941 45 43.0199C45 43.294 45.1409 43.5686 45.4125 43.7617C45.6357 43.9208 45.9139 44 46.1884 44C46.5207 44 46.8531 43.885 47.0871 43.661C48.1282 42.666 49.0485 41.5971 49.8403 40.471C49.948 40.3167 50 40.1469 50 39.9807C50.0007 39.6429 49.7876 39.314 49.4076 39.1323Z
                                 M21 29.999C21 29.4468 20.5525 29 20.0003 29C19.448 29 19 29.4468 19 29.999C19 30.5521 19.4475 31 20.0003 31C20.5525 31 21 30.5516 21 29.999Z"
                                fill="#63C55E"
                            />

                            <path
                                id={`${uniqId}-above`}
                                d="M2 27Q7 17 15 11 23 5 31 5 40 5 48 11 57 17 62 27"
                                display="none"
                            />

                            <path
                                id={`${uniqId}-below`}
                                d="M0 32Q0 38 2 42 6 49 10 52 16 59 31 59 47 59 54 52 58 49 62 42 64 37 64 32"
                                display="none"
                            />

                            <text dy="-15%">
                                <textPath
                                    href={`#${uniqId}-above`}
                                    startOffset="50%"
                                >
                                    {t("h_s_ls_christmas_logo_upper")}
                                    {/*Merry Christmas*/}
                                </textPath>
                            </text>

                            <text dy="15%">
                                <textPath
                                    href={`#${uniqId}-below`}
                                    startOffset="50%"
                                    textAnchor="middle"
                                >
                                    {t("h_s_ls_christmas_logo_lower")}
                                    {/*Happy New Year*/}
                                </textPath>
                            </text>
                        </svg>
                    </div>

                    <div className={styles.LoadingText}>
                        <LoadingScreenPhrase
                            phrase={phrase}
                            className={styles.LoadingTextContent}
                        />
                    </div>

                    <Corner className={cx(styles.Corner, styles.TopLeftCorner)} />
                    <Corner className={cx(styles.Corner, styles.TopRightCorner)} />
                    <Corner className={cx(styles.Corner, styles.BottomLeftCorner)} />
                    <Corner className={cx(styles.Corner, styles.BottomRightCorner)} />

                    <BottomLeft className={cx(styles.Icon, styles.BottomLeftIcon)} />
                    <BottomRight className={cx(styles.Icon, styles.BottomRightIcon)} />
                    <TopLeft className={cx(styles.Icon, styles.TopLeftIcon)} />
                    <TopRight className={cx(styles.Icon, styles.TopRightIcon)} />
                </div>
            </div>
        </AbstractCustomLoadingScreen>
    );
};

export default ChristmasScreen;