import React, {Component} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import cx from "classnames";
import styles from "./SearchBox.module.scss";
import Roomate from "../../../../components/common/Roomate";
import UserButton from "../../../../components/base/UserButton";
import {
    DestinationDestinationPropTypes,
    DestinationsResultPropTypes,
    HotelSearchCriteriaPropTypes,
    HotelSearchResultsFiltersPropTypes,
    NationalityResultPropTypes,
    RadiusDestinationPropTypes,
    RadiusPropTypes,
    RecentDestinationPropTypes,
    RecentSearchPropTypes,
    SavedSearchPropTypes
} from "proptypes/PropTypeObjects";
import CustomCheckBox from "../../../../components/base/Input/CustomCheckBox";
import {UserButtonVariant} from "components/base/UserButton/UserButton";
import ActionIcon, {ActionIconType} from "../../../../components/common/ActionIcon/ActionIcon";
import {HotelSearchFormParams} from "proptypes/PropTypeRedux";
import FeaturesFilter from "./FeaturesFilter/FeaturesFilter";
import HotelCategoryFilter from "./HotelCategoryFilter/HotelCategoryFilter";
import BoardTypeFilter from "./BoardTypeFilter/BoardTypeFilter";
import SpecialOffersAndCancellationPolicyFilter
    from "./SpecialOffersAndCancellationPolicyFilter/SpecialOffersAndCancellationPolicy";
import Input from "../../../../components/base/Input";
import SearchedItemsContainer from "./SearchedItemsContainer/SearchedItemsContainer";
import scrollToFormError from "../../../../utils/scrollToFormError";
import DateRangePicker from "../../../../components/base/Input/DatePicker/DateRangePicker/DateRangePicker"
import createLuxonDate from "../../../../utils/date/createLuxonDate"
import StandaloneDropdownWithSearch, {
    DropdownWithSearchOption
} from "../../../../components/base/Input/DropdownWithSearch/StandaloneDropdownWithSearch"
import {DebouncedFunc} from "lodash"
import destinationOptionSorter from "utils/hotel/destinationOptionSort";

/*
(ts-migrate) TODO: Migrate the remaining prop types
...ActionsWithTranslationPropTypes
*/
type SearchBoxProps = WithTranslation & {
    data: {
        boardTypes: { value: number | string; label: string; }[];
        features: { value: number, label: string }[];
        error?: string;
        destinations?: DestinationsResultPropTypes;
        recentSearches?: RecentSearchPropTypes[];
        savedSearches?: SavedSearchPropTypes[];
        nationalities: NationalityResultPropTypes;
        clientNationality?: string;
        nationalityInput?: string;
        destinationInput: string;
        checkIn?: string;
        checkOut?: string;
        rooms?: {
            adults: number;
            children: number[];
        }[];
        hotelId?: number;
        cityId?: number;
        airportId?: number;
        radius?: RadiusPropTypes;
        filters: HotelSearchResultsFiltersPropTypes;
        activeFilters: string[];
        size: number;
        nights: number;
        formParams: HotelSearchFormParams;
        ownUser?: boolean;
        currentDestination?: DestinationDestinationPropTypes | RecentDestinationPropTypes;
        showHotelId?: boolean;
    };

    handleSpecialOffersFilter: (checked: boolean) => void;
    handleMemberOnlyFilter: (checked: boolean) => void;
    handleOnlyGoodCXLFilter: (checked: boolean) => void;
    handleOnlyRefundableFilter: (checked: boolean) => void;
    handleOnlyNonRefundableFilter: (checked: boolean) => void;
    handleFeatures: (...args: any[]) => any;
    handleHotelCategory: (value: number | string, isActive: boolean) => void;
    handleAddRoom: (...args: any[]) => any;
    handleRemoveRoom: (...args: any[]) => any;
    handleChildrenChange: (...args: any[]) => any;
    handleAdultsChange: (...args: any[]) => any;
    handleFilterInputChange: (...args: any[]) => any;
    handleDateChange: (...args: any[]) => any;
    handleBoardType: (...args: any[]) => any;
    handleDestinations: (destination: DestinationDestinationPropTypes | RadiusDestinationPropTypes | RecentSearchPropTypes, destinationInput: string, group: string) => any;
    resetDestinations: (...args: any[]) => any;
    handleInputChange: (...args: any[]) => any;
    handleNatChange: (...args: any[]) => any;
    handleIgnoreSelectBestOffersChange: (checked: boolean) => void;
    handleMultiproviderChange: (checked: boolean) => void;
    handleLogRequestsChange: (checked: boolean) => void;
    resetNationalities: (...args: any[]) => any;
    handleSubmitSearch: (...args: any[]) => any;
    startSearchFromData: (criteria: HotelSearchCriteriaPropTypes, destination: RecentDestinationPropTypes) => void;
    getDestinations: DebouncedFunc<(value: string) => Promise<void>>;
};

type SearchBoxState = {
    showFilters: boolean,
};

class SearchBox extends Component<SearchBoxProps, SearchBoxState> {
    constructor(props: SearchBoxProps) {
        super(props);
        this.state = {
            showFilters: false
        };
    }

    handleValidationAndSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        if (!e) {
            return;
        }
        const form = e.target as HTMLFormElement;
        e.preventDefault();
        const valid = form.checkValidity();
        if (!valid) {
            scrollToFormError(form);
        }

        const {
            handleSubmitSearch
        } = this.props;

        if (valid) {
            handleSubmitSearch();
        }
    };

    toggleShowFilters = () => this.setState(({showFilters}) => ({showFilters: !showFilters}));

    render() {
        const {
            data: {
                destinations: {
                    destinations,
                    hotels,
                    airports,
                    radius,
                    recents,
                    destinationsCount = 0
                } = {},
                nationalities: {
                    nationalities = []
                } = {},
                clientNationality,
                recentSearches,
                nationalityInput,
                destinationInput,
                boardTypes,
                features,
                checkIn,
                checkOut,
                rooms,
                filters,
                nights,
                error,
                formParams,
                ownUser,
                currentDestination,
                showHotelId
            },
            handleSpecialOffersFilter,
            handleOnlyGoodCXLFilter,
            handleOnlyRefundableFilter,
            handleOnlyNonRefundableFilter,
            handleMemberOnlyFilter,
            handleFeatures,
            handleHotelCategory,
            handleAddRoom,
            handleRemoveRoom,
            handleChildrenChange,
            handleAdultsChange,
            handleFilterInputChange,
            handleDateChange,
            handleBoardType,
            handleDestinations,
            handleInputChange,
            handleNatChange,
            handleIgnoreSelectBestOffersChange,
            handleMultiproviderChange,
            handleLogRequestsChange,
            t,
            getDestinations
        } = this.props;

        const destinationOptions: DropdownWithSearchOption<DestinationDestinationPropTypes | RadiusDestinationPropTypes | RecentSearchPropTypes>[] = [];
        if (destinations) {
            destinationOptionSorter(destinations).forEach((item) => {
                destinationOptions.push(new DropdownWithSearchOption(
                    item.name,
                    item,
                    [item.stateName, item.countryName].filter((t) => !!t).join(", "),
                    "destinations"
                ))
            });
        }

        if (hotels) {
            destinationOptionSorter(hotels).forEach((item) => {
                destinationOptions.push(new DropdownWithSearchOption(
                    (ownUser && showHotelId ) ? ["(" +item.id + ")" + " " + item.name].filter((t) => !!t).join("") : item.name,
                    item,
                    [item.cityName, item.stateName, item.countryName].filter((t) => !!t).join(", "),
                    "hotels"
                ));
            })
        }

        if (airports) {
            destinationOptionSorter(airports).forEach((item) => {
                destinationOptions.push(new DropdownWithSearchOption<DestinationDestinationPropTypes | RadiusDestinationPropTypes | RecentSearchPropTypes>(
                    item.name,
                    item,
                    item.countryName,
                    "airports"
                ));
            })
        }

        if (radius) {
            radius.forEach((item) => {
                destinationOptions.push(new DropdownWithSearchOption(
                    item.name,
                    item,
                    item.countryName,
                    "radius"
                ))
            })
        }

        if (recents) {
            recents.forEach((item) => {
                const destination = item.destination;
                const cityName = destination.cityName;
                const countryName = destination.countryName;
                const hotelName = destination.hotelName;
                const radiusName = destination.radiusName;
                const airportName = destination.airportName;

                destinationOptions.push(new DropdownWithSearchOption(
                    destination
                        ? `${hotelName || cityName || airportName || radiusName}`
                        : "",
                    item,
                    (hotelName || airportName || radiusName)
                        ? [cityName, countryName].filter((t) => !!t).join(", ")
                        : `${countryName || ""}`,
                    "recents"
                ));
            })
        }

        // const destinationsFormed = {
        //     destinations: destinations && destinations.map((val: DestinationDestinationPropTypes) => ({
        //         value: val,
        //         label: `${val.name}, ${[val.stateName, val.countryName].filter((t) => !!t).join(", ")}`,
        //         element: <>{val.name}<span className="secondary-text">{[val.stateName, val.countryName].filter((t) => !!t).join(", ")}</span></>
        //     })),
        //     hotels: hotels && hotels.map((val: HotelDestinationPropTypes) => ({
        //         value: val,
        //         label: `${val.name}, ${[val.cityName, val.stateName, val.countryName].filter((t) => !!t).join(", ")}`,
        //         element: <>{val.name}<span className="secondary-text">{[val.cityName, val.stateName, val.countryName].filter((t) => !!t).join(", ")}</span></>
        //     })),
        //     airports: airports && airports.map((val: AirportDestinationPropTypes) => ({
        //         value: val,
        //         label: `${val.name}, ${val.countryName}`,
        //         element: <>{val.name}<span className="secondary-text">{val.countryName}</span></>
        //     })),
        //     radius: radius && radius.map((val: RadiusDestinationPropTypes) => ({
        //         value: val,
        //         label: `${val.name}, ${val.countryName}`,
        //         element: (
        //             <>
        //                 {val.name}
        //                 <span className="secondary-text">
        //                     {val.countryName}
        //                 </span>
        //             </>
        //         )
        //     })),
        //     recents: recents && recents.map((val: RecentSearchPropTypes) => ({
        //         value: {
        //             cityId: val.criteria.cityId || undefined,
        //             hotelId: val.criteria.hotelId || undefined,
        //             ...val.destination
        //         },
        //         label: val.destination ? `${val.destination.cityName || val.destination.hotelName || val.destination.airportName || val.destination.radiusName}, ${val.destination.countryName || ""}` : "",
        //         element: val.destination && (
        //             <>{val.destination.cityName || val.destination.hotelName || val.destination.airportName || val.destination.radiusName}
        //                 <span className="secondary-text">
        //                     {val.destination.countryName || ""}
        //                 </span>
        //             </>
        //         )
        //     }))
        // } as HotelDestinationsFormed;

        // const destinationsGroups = Object.keys(destinationsFormed)
        //     // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        //     .filter((key) => destinationsFormed[key]);
        // const destinationsGroupsLabels = destinationsGroups.map((key) => t("h_sb_search_group_" + key));
        const nationalitiesFormed = nationalities.map((nat) => new DropdownWithSearchOption(
            nat.name, nat.iso
        ));

        return (
            <div className={styles.HotelsSearchBox}>
                <div className={styles.SearchTitle}>
                    <h1 className={styles.SearchTitleText}>{t("h_s_sb_hotels_search_box_title")}</h1>
                </div>

                <form
                    id="hotel-search-form"
                    autoComplete="off"
                    noValidate
                    onSubmit={this.handleValidationAndSubmit}
                    className={cx(styles.SearchBox)}
                >
                    {error && (
                        <div className={styles.SearchError}>
                            <div className={styles.ErrorSymbol}><ActionIcon type={ActionIconType.ERROR}/></div>
                            <div className={styles.VDivider}/>
                            <div className={styles.ErrorText}>{t(error)}</div>
                        </div>
                    )}

                    {ownUser && (
                        <div className={cx(styles.OwnUserOptionsContainer)}>
                            <CustomCheckBox
                                label="Multiprovider Rooms"
                                inputProps={{
                                    checked: formParams.multiprovider || false,
                                    onChange: (e) => handleMultiproviderChange(e.target.checked)
                                }}
                            />

                            <CustomCheckBox
                                label="Ignore SBO"
                                inputProps={{
                                    checked: formParams.ignoreSelectBestOffers || false,
                                    onChange: (e) => handleIgnoreSelectBestOffersChange(e.target.checked)
                                }}
                            />

                            <CustomCheckBox
                                label="Collect logs"
                                inputProps={{
                                    checked: formParams.logRequests || false,
                                    onChange: (e) => handleLogRequestsChange(e.target.checked)
                                }}
                            />
                        </div>
                    )}

                    {recentSearches && recentSearches.length > 0 && (
                        <SearchedItemsContainer
                            recentSearches={recentSearches}
                            startSearchFromData={this.props.startSearchFromData}
                        />
                    )}

                    <div className={styles.SearchCriteria}>
                        {/*<DropdownGroupsWithSearch*/}
                        {/*    className={styles.DestinationInput}*/}
                        {/*    variants={{labelPosition: "outlined"}}*/}
                        {/*    customErrorMessage={t("util_vf_error_no_destination")}*/}
                        {/*    inputIcon={<SearchIcon key="HotelSearchInputIcon" />}*/}
                        {/*    inputProps={{*/}
                        {/*        required: true,*/}
                        {/*        name: "destinationInput",*/}
                        {/*        value: destinationInput,*/}
                        {/*        placeholder: t("h_s_sb_destinations_dropdown_placeholder"),*/}
                        {/*        onFocus: (e) => {*/}
                        {/*            this.props.getDestinations(e.target.value);*/}
                        {/*        },*/}
                        {/*        onChange: (e) => {*/}
                        {/*            handleInputChange(e);*/}
                        {/*            //resetDestinations()*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*    totalCount={destinationsCount}*/}
                        {/*    groups={destinationsGroups}*/}
                        {/*    groupLabels={destinationsGroupsLabels}*/}
                        {/*    onDropdownChange={handleDestinations}*/}
                        {/*    options={destinationsFormed}*/}
                        {/*/>*/}

                        <StandaloneDropdownWithSearch
                            filterEmptyMatch={false}
                            className={styles.DestinationInput}
                            dropdownClassName={styles.DestinationInputOptions}
                            options={destinationOptions}
                            customErrorMessage={t("util_vf_error_no_destination")}
                            smallFont
                            onValueChange={(opt) => {
                                handleDestinations(opt.value, opt.fullLabel(), opt.group || "");
                            }}
                            currentValue={currentDestination as DestinationDestinationPropTypes | RecentSearchPropTypes | RadiusDestinationPropTypes}
                            onSearchChange={getDestinations}
                            inputProps={{
                                // value: destinationInput,
                                name: "destinationInput",
                                placeholder: t("h_s_sb_destinations_dropdown_placeholder"),
                                onFocus: (e) => {
                                    getDestinations(e.target.value);
                                },
                                required: true
                            }}
                            groups={[
                                {
                                    order: 1,
                                    name: "destinations",
                                    label: t("h_sb_search_group_destinations"),
                                    endLabel: destinationsCount > 4 ? t("crs_dws_more") : undefined
                                },
                                {order: 2, name: "hotels", label: t("h_sb_search_group_hotels")},
                                {order: 3, name: "airports", label: t("h_sb_search_group_airports")},
                                {order: 4, name: "radius", label: t("h_sb_search_group_radius")},
                                {order: 5, name: "recents", label: t("h_sb_search_group_recents")}
                            ]}
                        />

                        {/*<DateRangePickerWrapper*/}
                        {/*    dateFrom={moment(checkIn)}*/}
                        {/*    dateTo={moment(checkOut)}*/}
                        {/*    nights={nights}*/}
                        {/*    handleDateChange={handleDateChange}*/}
                        {/*    offsetRight={true}*/}
                        {/*/>*/}

                        <DateRangePicker
                            className={styles.DatePicker}
                            from={createLuxonDate(checkIn)}
                            fromLabel={t("h_s_sb_checkin")}
                            to={createLuxonDate(checkOut)}
                            toLabel={t("h_s_sb_checkout")}
                            handleDateChange={handleDateChange}
                            validFrom={createLuxonDate().minus({day: 1})}
                        />
                    </div>

                    <div className={styles.SearchCriteria}>
                        {rooms?.map((val, i) => (
                            <Roomate
                                className={styles.Roomate}
                                key={i}
                                count={rooms?.length || 0}
                                index={i}
                                data={val}
                                handleAdultsChange={handleAdultsChange}
                                handleChildrenChange={handleChildrenChange}
                                handleRemoveRoom={handleRemoveRoom}
                            />
                        ))}
                    </div>

                    <div className={styles.SearchCriteria}>
                        <div
                            onClick={handleAddRoom}
                            className={cx(styles.AddAdditionalRoom, (rooms && rooms?.length >= 8) ? styles.AddAdditionalRoomDisabled : "")}
                        >
                            <span>+ {t("h_s_sb_add_additional_room")}</span>
                        </div>

                        <StandaloneDropdownWithSearch
                            currentValue={clientNationality}
                            className={styles.NationalityInput}
                            onValueChange={(opt) => {
                                handleNatChange(opt.value, opt.fullLabel(), opt.group);
                            }}
                            inputProps={{
                                required: true,
                                name: "nationalityInput",
                                placeholder: t("h_s_sb_client_nationality")
                            }}
                            // onDropdownChange={handleNatChange}
                            options={nationalitiesFormed}
                        />
                    </div>

                    {this.state.showFilters && (
                        <>
                            <div className={styles.Divider}/>
                            <div className={styles.SearchInputFilters}>
                                <Input
                                    className={styles.HotelNameInput}
                                    variants={{labelPosition: "outlined"}}
                                    inputProps={{
                                        onChange: handleFilterInputChange,
                                        value: filters.hotelNameFilter,
                                        name: "hotelNameFilter",
                                        type: "text",
                                        placeholder: t("h_s_sb_hotel_name")
                                    }}
                                />

                                <Input
                                    className={styles.RoomTypeInput}
                                    variants={{labelPosition: "outlined"}}
                                    inputProps={{
                                        onChange: handleFilterInputChange,
                                        value: filters.roomTypeFilter,
                                        name: "roomTypeFilter",
                                        type: "text",
                                        placeholder: t("h_s_sb_room_type_with_example")
                                    }}
                                />
                            </div>

                            <div className={styles.FiltersContainer}>
                                <FeaturesFilter
                                    onBoxSelect={handleFeatures}
                                    filters={filters}
                                    values={features}
                                />

                                <BoardTypeFilter
                                    onBoxSelect={handleBoardType}
                                    filters={filters}
                                    values={boardTypes}
                                />

                                <HotelCategoryFilter
                                    onChange={handleHotelCategory}
                                    filters={filters}
                                />

                                <SpecialOffersAndCancellationPolicyFilter
                                    filters={filters}
                                    onToggleGoodCXL={handleOnlyGoodCXLFilter}
                                    onToggleOnlyRefundable={handleOnlyRefundableFilter}
                                    onToggleOnlyNonRefundable={handleOnlyNonRefundableFilter}
                                    onToggleSpecialOffers={handleSpecialOffersFilter}
                                    onToggleMemberOnly={handleMemberOnlyFilter}
                                />
                            </div>
                        </>
                    )}
                    <div className={styles.SearchBottom}>
                        <div
                            onClick={this.toggleShowFilters}
                            className={cx(styles.FilterShowControl, this.state.showFilters && styles.FilterShowControlLess)}
                        >
                            {this.state.showFilters ? t("h_s_sb_less_filters") : t("h_s_sb_more_filters")}
                        </div>

                        <UserButton
                            className={styles.UserButton}
                            searchButton
                            text={t("h_s_sb_search_hotels_button_text")}
                            variant={UserButtonVariant.PRIMARY}
                            buttonProps={{
                                type: "submit",
                                style: {
                                    marginLeft: 30
                                }
                            }}
                        />
                    </div>
                </form>
            </div>
        );
    }
}

export default withTranslation()(SearchBox);
