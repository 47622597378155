import React, {ReactElement} from "react";
import {ReactComponent as SingleBed} from "assets/icons/features/single_bed.svg";
import {ReactComponent as TwinBeds} from "assets/icons/features/twin_beds.svg";
import {ReactComponent as LargeBed} from "assets/icons/features/large_bed.svg";
import {ReactComponent as TwoBeds} from "assets/icons/single bed.svg";


export default function getBedOptionsIcon(optionNr: string): ReactElement {
    switch (optionNr) {
    case "1":
        return <SingleBed />;
    case "2":
    case "6":
        return <LargeBed />;
    case "4":
        return <TwinBeds />;
    default: return <></>;
    }
};
