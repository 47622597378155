import React, {ReactElement, useCallback} from "react";
import {useNavigate} from "react-router-dom"
import cx from "classnames";
import createLuxonDate from "../../../utils/date/createLuxonDate"
import canCancelBooking from "../../../utils/canCancelBooking";
import MainContainer from "components/base/MainContainer";
import BookingStatus from "components/common/BookingStatus";
import PaymentInfo from "components/common/BookingStatus/PaymentInfo/PaymentInfo";
import BookingInfo from "components/common/BookingStatus/BookingInfo/BookingInfo";
import HotelInfo from "components/common/BookingStatus/HotelInfo/HotelInfo";
import RoomInfo, {RoomInfoWrapper} from "components/common/BookingStatus/RoomInfo";
import UserButton from "components/base/UserButton";
import DatesRangeBox from "components/common/DatesRangeBox";
import {useTranslation} from "react-i18next";
import BillingDetailsCheck from "components/utils/BillingDetailsCheck";
import CancelBooking from "../CancelBooking";
import Overpayment from "./Overpayment";
import PaymentPending from "./PaymentPending";
import Voucher from "./Voucher";
import {ClientBookingStatusPropTypes, WebHotelBookingDetails} from "proptypes/PropTypeObjects";
import {UserButtonVariant} from "../../base/UserButton/UserButton";
import styles from "./HotelStatus.module.scss";

type Props = {
    variants?: {
        afterBooking?: boolean;
        afterPayment?: boolean;
        cancellable?: boolean;
        withSidebar?: boolean;
        withInvoices?: boolean;
        canEditMemo?: boolean;
    };
    data: WebHotelBookingDetails & {
        mainStatus: ClientBookingStatusPropTypes;
        showBusinessCredentials?: boolean;
    },
    setShowBusinessCredentialsToFalse?: () => void;
    handlePaymentPending?: (activeOrder: any) => void;
    cancelBooking?: (id: number) => void;
    mainContainerClassName?: string;
};

function HotelStatus({
    data,
    variants,
    handlePaymentPending,
    cancelBooking,
    setShowBusinessCredentialsToFalse,
    mainContainerClassName
}: Props): ReactElement {
    const {
        id,
        bookingType,
        cxlDate,
        createDate,
        contactPerson,
        nationality,
        hotelName,
        hotelAddress,
        hotelId,
        currency,
        serviceStartDate,
        serviceEndDate,
        serviceName,
        status,
        mainStatus,
        reference,
        roomDetails,
        memo,
        specialRequests,
        paymentDeadline,
        paymentTransferType,
        paymentPending,
        retailPrice,
        paymentDeadlineDate,
        paymentDeadlineTime,
        autoCancellationDate,
        autoCancellationTime,
        canUseOverpayment,
        overpayment,
        overpaymentType,
        creditCardFee,
        autoCancel,
        paidAmount,
        price = 0,
        invoices,
        proformaInvoices,
        creditNotes,
        penaltyNotes,
        compensationNotes,
        voucherEmail,
        voucherEnabled,
        showBusinessCredentials,
        invoiceRecipient,
        customInvoice,
        discountUsageHistory
    } = data;
    const {t} = useTranslation();
    const navigate = useNavigate();

    const checkIn = createLuxonDate(serviceStartDate);
    const checkOut = createLuxonDate(serviceEndDate);
    const nights = Math.floor(checkOut.diff(checkIn, "days").days);
    const cxlDateFormed = createLuxonDate(cxlDate).toFormat("yyyy-MM-dd");
    const createdTimeAndDate = createLuxonDate(createDate).toFormat("yyyy-MM-dd HH:mm");
    const paymentPendingDaysLeft = Math.floor(createLuxonDate(paymentDeadline).diff(createLuxonDate(), "days").days);
    const overpaymentIsInRefund = overpaymentType === "REFUND";

    const payNowCallback = useCallback(() => {
        if (!handlePaymentPending) {
            return;
        }

        handlePaymentPending({
            bookingType,
            contactPerson,
            currency,
            cxlDate,
            id,
            paymentPending,
            price,
            reference,
            serviceEndDate,
            serviceStartDate,
            serviceName: serviceName || hotelName,
            leadPassenger: bookingType === "HOTEL" ? data.roomDetails[0].adults[0] : undefined
        });
    }, [bookingType, contactPerson, currency, cxlDate, data.roomDetails, handlePaymentPending, hotelName, id, paymentPending, price, reference, serviceEndDate, serviceName, serviceStartDate]);

    const cancelBookingCallback = useCallback(() => {
        if (cancelBooking) {
            cancelBooking(id);
        }
    }, [cancelBooking, id]);

    const sidebar = ((canUseOverpayment && overpayment > 0) || (paymentPending && paymentPending > 0) || voucherEnabled) && (
        <>
            {canUseOverpayment && overpayment > 0 && !overpaymentIsInRefund && (
                <Overpayment
                    currency={currency}
                    overpayment={overpayment}
                    id={id}
                />
            )}

            {paymentPending && paymentPending > 0 && handlePaymentPending ? (
                <PaymentPending
                    paymentDeadlineDate={paymentDeadlineDate}
                    paymentDeadlineTime={paymentDeadlineTime}
                    daysLeft={paymentPendingDaysLeft}
                    createDate={createDate}
                    paymentDeadline={paymentDeadline}
                    onPayNow={payNowCallback}
                    autoCancellationDate={autoCancellationDate}
                    autoCancellationTime={autoCancellationTime}
                />
            ) : undefined}

            {status !== "CANCELLED" && status !== "REJECTED" && status !== "CANCELLATION_PENDING" && (
                <Voucher
                    email={voucherEmail}
                    id={id}
                    voucherEnabled={voucherEnabled}
                />
            )}
        </>
    );

    return (
        <MainContainer
            sidebar={variants?.withSidebar && sidebar}
            className={styles.Root}
            containerClassName={mainContainerClassName}
        >
            {showBusinessCredentials && setShowBusinessCredentialsToFalse && (
                <BillingDetailsCheck
                    onConfirm={setShowBusinessCredentialsToFalse}
                    onDecline={setShowBusinessCredentialsToFalse}
                />
            )}

            <BookingStatus
                box={(
                    <DatesRangeBox
                        dashed
                        checkIn={checkIn}
                        checkOut={checkOut}
                        nights={nights}
                    />
                )}
                cxlDate={cxlDateFormed}
                createdTimeAndDate={createdTimeAndDate}
                bookingStatus={mainStatus}
                bookingReference={reference}
            >
                <HotelInfo
                    hotelName={hotelName}
                    address={hotelAddress}
                    id={hotelId}
                />

                <RoomInfoWrapper>
                    {roomDetails.map(({
                        price = 0,
                        roomType = "",
                        exactRoomType = "",
                        boardType = "",
                        adults,
                        children,
                        displayBookingTermsCXL,
                        displayBookingTermsRemarks,
                        amadeusLine
                    }, i: number) => (
                        <RoomInfo
                            id={id}
                            key={i}
                            roomNo={i}
                            price={price}
                            roomType={roomType}
                            exactRoomType={exactRoomType}
                            boardType={boardType}
                            currencyName={currency}
                            adultsData={adults}
                            childrenData={children}
                            displayBookingTermsCXL={displayBookingTermsCXL}
                            displayBookingTermsRemarks={displayBookingTermsRemarks}
                            amadeusLine={amadeusLine}
                            nationality={nationality}
                        />
                    ))}
                </RoomInfoWrapper>

                <div
                    className={cx(styles.MiddleWrapper, !variants?.withSidebar && variants?.withInvoices && styles.NoSidebar)}
                >
                    <BookingInfo
                        memo={memo}
                        checkIn={checkIn}
                        id={id}
                        contactInfo={{
                            title: contactPerson.title,
                            firstname: contactPerson.firstname,
                            lastname: contactPerson.lastname,
                            phone: contactPerson.phone,
                            email: contactPerson.email
                        }}
                        freeTransferIncluded={data.freeTransferIncluded}
                        reconfirmationDetails={data.reconfirmationDetails}
                        canEnterRequiredCheckInInfo={data.canEnterClientRequiredCheckInInformation}
                        specialRequests={specialRequests}
                        customInvoice={customInvoice}
                        invoiceRecipient={invoiceRecipient}
                        showSpecialRequests
                        canEditMemo={variants?.canEditMemo || false}
                        bookingStatus={mainStatus}
                        canEditClientRequiredCheckInInformation={!variants?.afterPayment}
                    />

                    {variants?.withInvoices && (
                        <PaymentInfo
                            id={id}
                            paymentDeadline={paymentDeadline}
                            paymentTransferType={paymentTransferType}
                            paymentPending={paymentPending}
                            retailPrice={retailPrice}
                            autoCancel={autoCancel}
                            paidAmount={paidAmount}
                            price={price}
                            currency={currency}
                            invoices={invoices}
                            proformaInvoices={proformaInvoices}
                            creditNotes={creditNotes}
                            penaltyNotes={penaltyNotes}
                            compensationNotes={compensationNotes}
                            creditCardFee={creditCardFee}
                            discountUsageHistory={discountUsageHistory}
                        />
                    )}
                </div>

                {(variants?.afterBooking || variants?.afterPayment) && (
                    <UserButton
                        text={t("bs_hs_go_to_booking")}
                        variant={UserButtonVariant.PRIMARY}
                        buttonProps={{
                            type: "button",
                            onClick: () => navigate(`/my-bookings/booking/${id}`)
                        }}
                    />
                )}
            </BookingStatus>

            {variants?.cancellable && cancelBooking && canCancelBooking(status, serviceStartDate) && (
                <CancelBooking
                    className={cx(!variants?.withSidebar && styles.NoSidebarCancel)}
                    cxlDate={cxlDate}
                    serviceStartDate={serviceStartDate}
                    onCancel={cancelBookingCallback}
                    status={status}
                />
            )}
        </MainContainer>
    );
}

export default HotelStatus;
