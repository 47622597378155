import React, {ReactElement, useCallback} from "react";
import {useTranslation} from "react-i18next";
import styles from "./AdvancedSearch.module.scss";
import Input from "../../../../components/base/Input";
import CustomCheckBox from "../../../../components/base/Input/CustomCheckBox";
import {
    BookingSearchCriteriaPropTypes,
    BookingTypeKeyValuePropTypes,
    BookingTypePropTypes,
    ClientBookingStatusKeyValuePropTypes,
    ClientBookingStatusPropTypes,
    PaymentStatusKeyValuePropTypes,
    PaymentStatusPropTypes
} from "proptypes/PropTypeObjects"
import DoubleDatePicker from "../../../../components/base/Input/DatePicker/DoubleDatePicker/DoubleDatePicker"
import createLuxonDate from "../../../../utils/date/createLuxonDate"
import StandaloneDropdownWithSearch, {
    DropdownWithSearchOption
} from "components/base/Input/DropdownWithSearch/StandaloneDropdownWithSearch";

export type AdvancedSearchProps = {
    localFormData: BookingSearchCriteriaPropTypes;
    handleTextInput: (event: { target: { name: string, value?: any } } | undefined) => void;

    countriesFormed: DropdownWithSearchOption<number | undefined>[];
    autoCancelOptions: DropdownWithSearchOption<boolean | undefined>[];
    subAgentsFormed: DropdownWithSearchOption<number | undefined>[];
    usersFormed: DropdownWithSearchOption<number | undefined>[];
    subFranchiseCompaniesFormed: DropdownWithSearchOption<number | undefined>[];

    handleCreatedDate: ({startDate, endDate, resetQuickFilters}: any) => void;
    handleServiceStartDate: ({startDate, endDate, resetQuickFilters}: {
        startDate?: any,
        endDate?: any,
        resetQuickFilters?: boolean;
    }) => void;

    handleBookingStatuses: (statuses: ClientBookingStatusPropTypes[], resetQuickFilters?: boolean) => void;
    bookingStatuses: ClientBookingStatusKeyValuePropTypes[];

    handlePaymentStatuses: (paymentStatuses: PaymentStatusPropTypes[]) => void;
    paymentStatuses: PaymentStatusKeyValuePropTypes[];

    serviceTypes: BookingTypeKeyValuePropTypes[];

    dispatch: React.Dispatch<any>;
};

export default function AdvancedSearch({
    localFormData,
    handleTextInput,

    countriesFormed,
    autoCancelOptions,
    usersFormed,
    subAgentsFormed,
    subFranchiseCompaniesFormed,

    handleCreatedDate,
    handleServiceStartDate,

    handleBookingStatuses,
    bookingStatuses,

    handlePaymentStatuses,
    paymentStatuses,

    serviceTypes,

    dispatch
}: AdvancedSearchProps): ReactElement {
    const {t} = useTranslation();

    const handleCountriesId = useCallback((destinationCountryId: any) => dispatch({
        type: "HANDLE_FORM_DATA",
        formData: {
            destinationCountryId
        }
    }), [dispatch]);

    const handleSubAgentId = useCallback((subAgentId: number | undefined) => dispatch({
        type: "HANDLE_FORM_DATA",
        formData: {
            subAgentId
        }
    }), [dispatch]);

    const handleUserId = useCallback((userId: number | undefined) => dispatch({
        type: "HANDLE_FORM_DATA",
        formData: {
            userId
        }
    }), [dispatch]);

    const handleSubFranchiseCompanyId = useCallback((subFranchiseCompanyId: number | undefined) => dispatch({
        type: "HANDLE_FORM_DATA",
        formData: {
            subFranchiseCompanyId
        }
    }), [dispatch]);

    const handleAutoCancel = useCallback((autoCancel: DropdownWithSearchOption<boolean | undefined>) => dispatch({
        type: "HANDLE_FORM_DATA",
        formData: {
            autoCancel: autoCancel.value
        }
    }), [dispatch]);

    const handleServiceTypes = useCallback((types: any) => dispatch({
        type: "HANDLE_FORM_DATA",
        formData: {
            types
        }
    }), [dispatch]);

    const handleInvoiceDate = useCallback(({
        startDate = localFormData.invoiceDateFrom,
        endDate = localFormData.invoiceDateTo
    }) => dispatch({
        type: "HANDLE_FORM_DATA",
        formData: {
            invoiceDateFrom: startDate,
            invoiceDateTo: endDate
        }
    }), [dispatch, localFormData.invoiceDateFrom, localFormData.invoiceDateTo]);

    const handlePaymentDeadlineDate = useCallback(({
        startDate = localFormData.paymentDeadlineFrom,
        endDate = localFormData.paymentDeadlineTo
    }) => dispatch({
        type: "HANDLE_FORM_DATA",
        formData: {
            paymentDeadlineFrom: startDate,
            paymentDeadlineTo: endDate
        }
    }), [
        dispatch,
        localFormData.paymentDeadlineFrom,
        localFormData.paymentDeadlineTo
    ]);

    const handleCXLDate = useCallback(({
        startDate = localFormData.cxlDateFrom,
        endDate = localFormData.cxlDateTo
    }) => dispatch({
        type: "HANDLE_FORM_DATA",
        formData: {
            cxlDateFrom: startDate,
            cxlDateTo: endDate
        }
    }), [dispatch, localFormData.cxlDateFrom, localFormData.cxlDateTo]);

    const handleServiceTypeChange = useCallback((e: BookingTypePropTypes, enabled: boolean) => {
        if (enabled) {
            handleServiceTypes(localFormData.types ? [...localFormData.types, e] : [e]);
        } else {
            handleServiceTypes(localFormData.types.filter((type: BookingTypePropTypes) => type !== e));
        }
    }, [handleServiceTypes, localFormData.types]);

    const handleBookingStatusChange = useCallback((e: ClientBookingStatusPropTypes, enabled: boolean) => {
        if (enabled) {
            handleBookingStatuses(localFormData.statuses ? [...localFormData.statuses, e] : [e]);
        } else {
            handleBookingStatuses(localFormData.statuses.filter((type: ClientBookingStatusPropTypes) => type !== e));
        }
    }, [handleBookingStatuses, localFormData.statuses]);

    const handleBookingPaymentStatusChange = useCallback((e: PaymentStatusPropTypes, enabled: boolean) => {
        if (enabled) {
            handlePaymentStatuses(localFormData.paymentStatuses ? [...localFormData.paymentStatuses, e] : [e]);
        } else {
            handlePaymentStatuses(localFormData.paymentStatuses.filter((type: PaymentStatusPropTypes) => type !== e));
        }
    }, [handlePaymentStatuses, localFormData.paymentStatuses]);

    return (
        <div className={styles.Root}>
            <Input
                className={styles.SharedInput}
                fullHeight={false}
                variants={{labelPosition: "outlined"}}
                inputProps={{
                    name: "textSearch",
                    value: localFormData.textSearch,
                    onChange: handleTextInput,
                    placeholder: t("mb_smb_search_booking_search_placeholder")
                }}
            />

            <Input
                className={styles.CommonInput}
                fullHeight={false}
                variants={{labelPosition: "outlined"}}
                inputProps={{
                    placeholder: t("mb_smb_booking_reference"),
                    name: "bookingReference",
                    onChange: handleTextInput,
                    value: localFormData.bookingReference
                }}
            />

            <Input
                className={styles.CommonInput}
                fullHeight={false}
                variants={{labelPosition: "outlined"}}
                inputProps={{
                    placeholder: t("mb_smb_agent_booking_reference"),
                    name: "agentReference",
                    value: localFormData.agentReference,
                    onChange: handleTextInput
                }}
            />

            <StandaloneDropdownWithSearch
                className={styles.CommonInput}
                inputProps={{
                    name: "destinationCountryInput",
                    value: localFormData.destinationCountryInput || "",
                    placeholder: t("mb_smb_destination_country"),
                    onChange: handleTextInput
                }}
                small
                onValueChange={(value: DropdownWithSearchOption<number | undefined>) => {
                    handleCountriesId(value.value);
                    handleTextInput({
                        target: {
                            name: "destinationCountryInput",
                            value: value.label
                        }
                    });
                }}
                options={countriesFormed}
            />

            <Input
                className={styles.CommonInput}
                fullHeight={false}
                variants={{labelPosition: "outlined"}}
                inputProps={{
                    placeholder: t("mb_smb_invoice_number"),
                    onChange: handleTextInput,
                    name: "invoiceNumber",
                    value: localFormData.invoiceNumber
                }}
            />

            <Input
                className={styles.CommonInput}
                fullHeight={false}
                variants={{labelPosition: "outlined"}}
                inputProps={{
                    placeholder: t("mb_smb_pax_name"),
                    name: "paxName",
                    value: localFormData.paxName,
                    onChange: handleTextInput
                }}
            />

            <StandaloneDropdownWithSearch
                className={styles.CommonInput}
                inputProps={{
                    placeholder: t("mb_smb_auto_cancel")
                }}
                options={autoCancelOptions}
                onValueChange={handleAutoCancel}
                searchEnabled={false}
                currentValue={localFormData.autoCancel}
                filterEmptyMatch={false}
            />

            {/*<BasicDropdown<boolean | undefined, string>*/}
            {/*    hideErrors={true}*/}
            {/*    className={styles.CommonInput}*/}
            {/*    label={t("mb_smb_auto_cancel")}*/}
            {/*    dropDownOptions={autoCancelOptions}*/}
            {/*    onDropdownChange={handleAutoCancel}*/}
            {/*    dropDownValue={localFormData.autoCancel}*/}
            {/*/>*/}

            {subAgentsFormed.length > 0 && !localFormData.franchiseeBookings && (
                <StandaloneDropdownWithSearch
                    className={styles.CommonInput}
                    inputProps={{
                        name: "subAgentInput",
                        value: localFormData.subAgentInput || "",
                        placeholder: t("mb_smb_sub_agent"),
                        onChange: handleTextInput
                    }}
                    small
                    onValueChange={(value: DropdownWithSearchOption<number | undefined>) => {
                        handleSubAgentId(value.value);
                        handleTextInput({
                            target: {
                                name: "subAgentInput",
                                value: value.label
                            }
                        });
                    }}
                    currentValue={localFormData.subAgentId}
                    options={subAgentsFormed}
                />
            )}

            {usersFormed.length > 0 && !localFormData.franchiseeBookings && (
                <StandaloneDropdownWithSearch
                    className={styles.CommonInput}
                    inputProps={{
                        name: "userInput",
                        value: localFormData.userInput || "",
                        placeholder: t("mb_smb_user"),
                        onChange: handleTextInput
                    }}
                    small
                    onValueChange={(value: DropdownWithSearchOption<number | undefined>) => {
                        handleUserId(value.value);
                        handleTextInput({
                            target: {
                                name: "userInput",
                                value: value.label
                            }
                        });
                    }}
                    options={usersFormed}
                />
            )}

            {subFranchiseCompaniesFormed.length > 0 && localFormData.franchiseeBookings && (
                <StandaloneDropdownWithSearch
                    className={styles.CommonInput}
                    inputProps={{
                        name: "subFranchiseCompanies",
                        value: localFormData.subFranchiseInput || "",
                        placeholder: t("mb_smb_franchise_companies"),
                        onChange: handleTextInput
                    }}
                    small
                    onValueChange={(value: DropdownWithSearchOption<number | undefined>) => {
                        handleSubFranchiseCompanyId(value.value);
                        handleTextInput({
                            target: {
                                name: "subFranchiseInput",
                                value: value.label
                            }
                        });
                    }}
                    options={subFranchiseCompaniesFormed}
                />
            )}


            <div className={styles.OtherFiltersContainer}>
                <div className={styles.DatesContainer}>
                    {/*<DateRangeInput*/}
                    {/*    label={}*/}
                    {/*    readOnly={false}*/}
                    {/*    double*/}
                    {/*    startDate={localFormData.createDateFrom ? moment(localFormData.createDateFrom, moment.ISO_8601) : null}*/}
                    {/*    index={0}*/}
                    {/*    endDate={localFormData.createDateTo ? moment(localFormData.createDateTo, moment.ISO_8601) : null}*/}
                    {/*    onStartDateChange={}*/}
                    {/*    onEndDateChange={}*/}
                    {/*    startDatePlaceholderText={t("mb_smb_date_from")}*/}
                    {/*    endDatePlaceholderText={t("mb_smb_date_until")}*/}
                    {/*/>*/}

                    <DoubleDatePicker
                        label={t("mb_smb_created")}
                        from={localFormData.createDateFrom ? createLuxonDate(localFormData.createDateFrom) : null}
                        fromPlaceholder={t("mb_smb_date_from")}
                        to={localFormData.createDateTo ? createLuxonDate(localFormData.createDateTo) : null}
                        toPlaceholder={t("mb_smb_date_until")}
                        onDateFromChange={(startDate) => handleCreatedDate({
                            startDate: startDate ? createLuxonDate(startDate).toISO() : null,
                            endDate: localFormData.createDateTo,
                            resetQuickFilters: false
                        })}
                        onDateToChange={(endDate) => handleCreatedDate({
                            startDate: localFormData.createDateFrom,
                            endDate: endDate ? createLuxonDate(endDate).toISO() : null,
                            resetQuickFilters: false
                        })}
                    />

                    <DoubleDatePicker
                        label={t("mb_smb_invoiced")}
                        from={localFormData.invoiceDateFrom ? createLuxonDate(localFormData.invoiceDateFrom) : null}
                        fromPlaceholder={t("mb_smb_date_from")}
                        to={localFormData.invoiceDateTo ? createLuxonDate(localFormData.invoiceDateTo) : null}
                        toPlaceholder={t("mb_smb_date_until")}
                        onDateFromChange={(startDate) => handleInvoiceDate({
                            startDate: startDate ? createLuxonDate(startDate).toISO() : null,
                            endDate: localFormData.invoiceDateTo
                        })}
                        onDateToChange={(endDate) => handleInvoiceDate({
                            startDate: localFormData.invoiceDateFrom,
                            endDate: endDate ? createLuxonDate(endDate).toISO() : null
                        })}
                    />

                    <DoubleDatePicker
                        label={t("mb_smb_payment_deadline")}
                        from={localFormData.paymentDeadlineFrom ? createLuxonDate(localFormData.paymentDeadlineFrom) : null}
                        fromPlaceholder={t("mb_smb_date_from")}
                        to={localFormData.paymentDeadlineTo ? createLuxonDate(localFormData.paymentDeadlineTo) : null}
                        toPlaceholder={t("mb_smb_date_until")}
                        onDateFromChange={(startDate) => handlePaymentDeadlineDate({
                            startDate: startDate ? createLuxonDate(startDate).toISO() : null,
                            endDate: localFormData.paymentDeadlineTo
                        })}
                        onDateToChange={(endDate) => handlePaymentDeadlineDate({
                            startDate: localFormData.paymentDeadlineFrom,
                            endDate: endDate ? createLuxonDate(endDate).toISO() : null
                        })}
                    />

                    <DoubleDatePicker
                        label={t("mb_smb_cxl")}
                        from={localFormData.cxlDateFrom ? createLuxonDate(localFormData.cxlDateFrom) : null}
                        fromPlaceholder={t("mb_smb_date_from")}
                        to={localFormData.cxlDateTo ? createLuxonDate(localFormData.cxlDateTo) : null}
                        toPlaceholder={t("mb_smb_date_until")}
                        onDateFromChange={(startDate) => handleCXLDate({
                            startDate: startDate ? createLuxonDate(startDate).toISO() : null,
                            endDate: localFormData.cxlDateTo
                        })}
                        onDateToChange={(endDate) => handleCXLDate({
                            startDate: localFormData.cxlDateFrom,
                            endDate: endDate ? createLuxonDate(endDate).toISO() : null
                        })}
                    />

                    <DoubleDatePicker
                        label={t("mb_smb_service_starts")}
                        from={localFormData.serviceStartDateFrom ? createLuxonDate(localFormData.serviceStartDateFrom) : null}
                        fromPlaceholder={t("mb_smb_date_from")}
                        to={localFormData.serviceStartDateTo ? createLuxonDate(localFormData.serviceStartDateTo) : null}
                        toPlaceholder={t("mb_smb_date_until")}
                        onDateFromChange={(startDate) => handleServiceStartDate({
                            startDate: startDate ? createLuxonDate(startDate).toISO() : null,
                            endDate: localFormData.serviceStartDateTo,
                            resetQuickFilters: false
                        })}
                        onDateToChange={(endDate) => handleServiceStartDate({
                            startDate: localFormData.serviceStartDateFrom,
                            endDate: endDate ? createLuxonDate(endDate).toISO() : null,
                            resetQuickFilters: false
                        })}
                    />
                </div>

                <div className={styles.StatusesContainer}>
                    <div className={styles.StatusContainer}>
                        <div className={styles.StatusContainerHeading}>{t("mb_smb_service_type")}</div>

                        {serviceTypes.filter((type) => type.value !== "VISA").map((type, i) => (
                            <CustomCheckBox
                                key={type.key}
                                className="checkbox"
                                inputProps={{
                                    checked: localFormData.types?.includes(type.value),
                                    onChange: (evt) => handleServiceTypeChange(type.value, evt.target.checked)
                                }}
                                label={(
                                    <span className={styles.CheckboxLabel}>
                                        {t(type.key)}
                                    </span>
                                )}
                            />
                        ))}
                    </div>

                    <div className={styles.StatusContainer}>
                        <div className={styles.StatusContainerHeading}>{t("mb_smb_booking_status")}</div>

                        {bookingStatuses.filter((type) => type.value !== "NEW").map((type, i) => (
                            <CustomCheckBox
                                key={type.key}
                                className="checkbox"
                                inputProps={{
                                    checked: localFormData.statuses?.includes(type.value),
                                    onChange: (evt) => handleBookingStatusChange(type.value, evt.target.checked)
                                }}
                                label={(
                                    <span className={styles.checkboxLabel}>
                                        {t(type.key)}
                                    </span>
                                )}
                            />
                        ))}
                    </div>

                    <div className={styles.StatusContainer}>
                        <div className={styles.StatusContainerHeading}>{t("mb_smb_payment_status")}</div>

                        {paymentStatuses.map((type, i) => (
                            <CustomCheckBox
                                key={type.key}
                                className="checkbox"
                                inputProps={{
                                    checked: localFormData.paymentStatuses?.includes(type.value),
                                    onChange: (evt) => handleBookingPaymentStatusChange(type.value, evt.target.checked)
                                }}
                                label={(
                                    <span className={styles.checkboxLabel}>
                                        {t(type.key)}
                                    </span>
                                )}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}