import React, {ReactElement, useCallback, useState} from "react";
import bigBus from "assets/images/transfers/big bus.png";
import luxPrivateCar from "assets/images/transfers/lux private car.png";
import other from "assets/images/transfers/other.png";
import privateTransfer from "assets/images/transfers/private transfer.png";
import UserButton from "components/base/UserButton";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import styles from "./Transfer.module.scss";
import {TransferOfferPropTypes} from "proptypes/PropTypeObjects";
import {UserButtonVariant} from "components/base/UserButton/UserButton";
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_utils/currency/getPriceWithCurrency";
import {useAppSelector} from "redux/hooks";
import {RootState} from "redux/store/store.init";
import Tippy from "../../../../components/common/Tippy";
import Currency from "@hotelston_web_frontend_parent/constants/currency";

function getTransferImageSrc(type: string) {
    switch (type) {
    case "private-car":
        return privateTransfer;
    case "private-luxury-car":
        return luxPrivateCar;
    case "shuttle":
        return bigBus;
    default:
        return other;
    }
}

type Props = {
    data: TransferOfferPropTypes & {
        pax: number;
        transferAmount: number;
        currency: Currency;
    }
    getBookingOptions(args_0: any): any;
};

function Transfer({data, getBookingOptions}: Props): ReactElement {
    const {
        displayInboundPrice,
        displayOutboundPrice,
        displaySalePrice,
        transfer,
        transferType,
        numberOfUnits,
        pax,
        transferAmount,
        currency,
        distanceKm,
        durationMin,
        remark,
        providerName
    } = data;

    const {t} = useTranslation();
    const [descriptionExpanded, setDescriptionExpanded] = useState(false);

    const allowToBook = useAppSelector((state: RootState) => state.auth.userData?.company?.allowToBook);
    const relatedBooking = useAppSelector((state) => state.transferSearch.formData.relatedBooking);

    const buildBookButton = useCallback(() => (
        <UserButton
            text={t("t_sr_t_book_button")}
            variant={UserButtonVariant.PRIMARY}
            buttonProps={{
                type: "button",
                style: {
                    height: "40px"
                },
                onClick: () => getBookingOptions({
                    transferId: transfer?.id,
                    transferTypeId: transferType.id,
                    transferName: transferType.name,
                    pax
                })
            }}
        />
    ), []);

    return (
        <div className={styles.Root}>
            <div className={styles.ImageContainer}>
                <img src={getTransferImageSrc(transferType.icon)} alt={transferType.name} />
            </div>

            <div className={styles.Content}>
                <div className={cx(styles.Description, descriptionExpanded && styles.DescriptionExpanded)}>
                    <h3>{transferType.name}</h3>

                    <div className={styles.DescriptionContent}>
                        {transferType.description}
                    </div>

                    {transferType.description && !descriptionExpanded && (
                        <span className={styles.ShowMore} onClick={() => setDescriptionExpanded(true)}>
                            {t("t_sr_t_view")}
                        </span>
                    )}

                    {transferType.description && descriptionExpanded && (
                        <span className={styles.ShowLess} onClick={() => setDescriptionExpanded(false)}>
                            {t("t_sr_t_less_button")}
                        </span>
                    )}
                </div>

                <div className={styles.Listings}>
                    <ul className={styles.List}>
                        {!!transferType.name && (
                            <li>
                                {t("t_sr_t_type")}:
                                <span className={styles.SecondaryText}>
                                    {transferType.name}
                                </span>
                            </li>
                        )}

                        {!!transferType.minPax && !!transferType.maxPax && (
                            <li>
                                {t("t_sr_t_occupancy")}:
                                <span className={styles.SecondaryText}>
                                    {transferType.minPax} - {transferType.maxPax}
                                </span>
                            </li>
                        )}

                        {!!numberOfUnits && (
                            <li>
                                {t("t_sr_t_no_of_units")}:
                                <span className={styles.SecondaryText}>
                                    {numberOfUnits}
                                </span>
                            </li>
                        )}

                        {distanceKm && (
                            <li>
                                {t("t_sr_t_distance")}:
                                <span className={styles.SecondaryText}>
                                    {distanceKm}km
                                </span>
                            </li>
                        )}

                        {durationMin && (
                            <li>
                                {t("t_sr_t_duration")}:
                                <span className={styles.SecondaryText}>
                                    {durationMin}min
                                </span>
                            </li>
                        )}

                        {remark && (
                            <li>
                                {t("t_sr_t_remark")}:
                                <span className={styles.SecondaryText}>
                                    {remark}
                                </span>
                            </li>
                        )}
                    </ul>

                    <ul className={styles.List}>
                        {!!displayInboundPrice && (
                            <li>
                                {t("t_sr_t_inbound")}:
                                <span className={styles.SecondaryText}>
                                    {getPriceWithCurrencySymbol(displayInboundPrice * transferAmount, currency)}
                                </span>
                            </li>
                        )}

                        {!!displayOutboundPrice && (
                            <li>
                                {t("t_sr_t_outbound")}:
                                <span className={styles.SecondaryText}>
                                    {getPriceWithCurrencySymbol(displayOutboundPrice * transferAmount, currency)}
                                </span>
                            </li>
                        )}

                        {!!displaySalePrice && (
                            <li>
                                {t("t_sr_t_total")}:
                                <span className={styles.SecondaryText}>
                                    {getPriceWithCurrencySymbol(displaySalePrice * transferAmount, currency)}
                                </span>
                            </li>
                        )}

                        {providerName && (
                            <li>
                                {t("hsb_sb_provider")}:
                                <span className={styles.SecondaryText}>
                                    {providerName}
                                </span>
                            </li>
                        )}
                    </ul>
                </div>
            </div>

            <div className={styles.PriceBox}>
                <div className={styles.PriceBoxLabel}>
                    {t("t_sr_t_price_for_pax", {pax})}
                </div>

                {!!relatedBooking && (
                    <div className={styles.DiscountLabel}>
                        {t("t_sr_t_package_discount_applied", {percent: 10})}
                    </div>
                )}

                <h4 className={styles.Price}>
                    {getPriceWithCurrencySymbol(displaySalePrice * transferAmount, currency)}
                </h4>

                {allowToBook ? buildBookButton() : (
                    <Tippy content={(
                        <span>{t("co_co_net_price_notice")}</span>
                    )}
                    >
                        <div>
                            {buildBookButton()}
                        </div>
                    </Tippy>
                )}
            </div>
        </div>
    );
}

export default Transfer;
