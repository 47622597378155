import {ReactElement} from "react";
import cx from "classnames";
import styles from "./SelectBox.module.scss";

type Props = {
    label: string;
    onBoxSelect: () => void;
    activeState: boolean;
}

function SelectBox({label, onBoxSelect, activeState}: Props): ReactElement {

    return (

        <div className={styles.Root}>
            <button
                type="button"
                className={cx(styles.Item, activeState && styles.Active)}
                onClick={onBoxSelect}
            >
                <span className={styles.Label}>
                    {label}
                </span>
            </button>
        </div>
    );
}

export default SelectBox;