import {RoomDescriptionPropTypes} from "proptypes/PropTypeObjects";
import {RoomMoreInfoModal} from "components/common/RoomMoreInfo/RoomMoreInfoModal";
import styles from "./RoomMoreInfo.module.scss";

type props = {
    roomDescriptions: RoomDescriptionPropTypes[];
}

function RoomsWithMoreInfo({roomDescriptions}: props) {

    return (
        roomDescriptions?.length > 0 && (
            <div className={styles.RoomDescriptionBlock}>
                {roomDescriptions.map((room) => (
                    <RoomMoreInfoModal key={room.providerRoomId} data={room} />
                ))}
            </div>
        )
    );
}

export default RoomsWithMoreInfo;