import React from "react";
import styles from "views/Hotels/Searching/LoadingScreen/CustomLoadingScreen/AbstractCustomLoadingScreen.module.scss"
import cx from "classnames"
import LoadingScreenPhrase from "../LoadingScreenPhrase/LoadingScreenPhrase"

type AbstractCustomLoadingScreenProps = {
  currentDestination: string;
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  customImageBackgroundUrl?: string;
  noMessage?: boolean;
}

const AbstractCustomLoadingScreen = (
    props: AbstractCustomLoadingScreenProps
) => {
    const {
        currentDestination,
        children,
        className,
        customImageBackgroundUrl,
        noMessage = false
    } = props;

    return (
        <div
            className={cx(styles.Root, className)}
            style={
                customImageBackgroundUrl ? {
                    backgroundImage: `url(${customImageBackgroundUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center"
                } : undefined
            }
        >
            {!noMessage && (
                <LoadingScreenPhrase phrase={currentDestination} />
            )}

            <div className={styles.LoadingScreenContent}>
                {children}
            </div>
        </div>
    )
}

export default AbstractCustomLoadingScreen