import React from "react";
import styles from "./LoadingScreenPhrase.module.scss"
import cx from "classnames"

type LoadingScreenPhraseProps = {
  phrase: string;
  className?: string;
}

const LoadingScreenPhrase = (props: LoadingScreenPhraseProps) => {
    const {
        phrase,
        className
    } = props;

    return (
        <div className={cx(styles.Root, className)}>
            <div className={styles.Spinner} />

            <p>
                {phrase}
            </p>
        </div>
    );
}

export default LoadingScreenPhrase;