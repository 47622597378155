import React, {ReactElement} from "react";
import {ReactComponent as NonSmoking} from "assets/icons/features/non_smoking.svg";
import {ReactComponent as Smoking} from "assets/icons/features/smoking.svg";
import {ReactComponent as Bag} from "assets/icons/bag.svg";

export default function getAttributeIcon(option: string): ReactElement {
    switch (option) {
        case "non_smoking":
            return <NonSmoking />;
        case "smoking":
            return <Smoking />;
        case "work_friendly":
            return <Bag />
        default: return <></>;
    }
};
