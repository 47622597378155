import React, {ReactElement} from "react";
import {ReactComponent as Bathroom} from "assets/icons/features/bathroom.svg";
import {ReactComponent as Bedroom} from "assets/icons/features/bedroom.svg";
import {ReactComponent as Reception} from "assets/icons/features/reception.svg";

export default function getRoomOptionsIcon(option: string): ReactElement {
    switch (option) {
    case "Bedrooms":
        return <Bedroom />;
    case "Bathrooms":
        return <Bathroom />;
    case "LivingRooms":
        // TODO search for icon
        return <Reception />;
    default: return <></>;
    }
};
