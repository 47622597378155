import React, {ReactElement} from "react";
import {Route, Routes} from "react-router-dom";
import HeaderContainer from "../../components/base/Header/HeaderContainer";
import Footer from "../../components/base/Footer";
import Lefter from "../../components/base/Lefter/LefterContainer";
import Righter from "../../components/base/Righter";
import PageTemplate from "../../components/base/PageTemplate/PageTemplate";
import ErrorBoundary from "../../components/utils/ErrorBoundary/ErrorBoundary";
import UnsignedAgreement from "./UnsignedAgreement/UnsignedAgreement";
import SubagentBillingDetails from "views/ActionRedirect/SubagentBillingDetails/SubagentBillingDetails";
import NotVerifiedAgreement from "views/ActionRedirect/NotVerifiedAgreement/NotVerifiedAgreement";

const ActionRedirect = (): ReactElement => (
    <PageTemplate
        header={<HeaderContainer/>}
        footer={<Footer/>}
        lefter={<Lefter/>}
        righter={<Righter/>}
    >
        <ErrorBoundary>
            <Routes>
                <Route
                    path="/unsigned-agreement"
                    element={<UnsignedAgreement/>}
                />

                <Route
                    path="/subagent-unsigned-agreement"
                    element={<SubagentBillingDetails/>}
                />

                <Route
                    path="/not-verified-agreement"
                    element={<NotVerifiedAgreement />}
                />
            </Routes>
        </ErrorBoundary>
    </PageTemplate>
);

export default ActionRedirect;