import React, {ReactElement, useCallback, useEffect, useState} from "react"
import {connect, ConnectedProps} from "react-redux";
import Hotel from "./Hotel";
import {handleFormData, submitSearch} from "redux/actions/hotelSearch.actions";
import {checkQueryParams} from "redux/actions/hotelSearchResults.actions";
import {logout} from "redux/actions/auth.actions";
import withPinnedOffers from "../../../components/utils/withPinnedOffers";
import {
    HotelDetails, HotelOfferPropTypes,
    HotelPropTypes,
    HotelSearchCriteriaPropTypes, HotelSearchResultsFiltersPropTypes, RoomDescriptionPropTypes,
    RoomOfferPropTypes, SimpleHotelOfferPropTypes
} from "proptypes/PropTypeObjects";
import {RootState} from "redux/store/store.init";
import {submitFilters, getHotelsDetailsOnly, handleInputChange} from "redux/actions/hotelSearch.base.actions"
import Spinner from "../../../components/base/Loaders/Spinner";
import {changeMapTypeId} from "redux/actions/map.actions";
import {useLocation, useParams} from "react-router-dom";
import router from "views/router/router"
import {handleFilters} from "redux/actions/hotelSearchResults.base.actions"

type Props = ConnectedProps<typeof connector>;

const HotelContainer = (props: Props): ReactElement => {
    const {
        searchError,
        formData,
        filters,
        activeFilters,
        isSearching,
        pinnedOffers: {
            slide: isActivePinnedOffers
        },
        dataForFilters,
        datahotelOffers,
        mapTypeId,
        changeMapTypeId,
        locale,
        nationalityInput,
        getHotelsDetailsOnly,
        checkQueryParams,
        submitedSearch,
        submitedFilters,

        handleFilters,
        handleFormData,
        handleInputChange,
        submitSearch,
        submitFilters
    } = props;

    const [zoom, setZoom] = useState<number>(10);
    const [center, setCenter] = useState<google.maps.LatLng>({lat: 0, lng: 0} as unknown as google.maps.LatLng);
    const [fullWidthMap, setFullWidthMap] = useState<boolean>(false);
    const [searchCriteriaChanged, setSearchCriteriaChanged] = useState<boolean>(false);
    const [hotel, setHotel] = useState<HotelPropTypes | undefined>(undefined);
    const [error, setError] = useState<string | undefined>();
    const [backup, setBackup] = useState<{
        nationalityInput?: string,
        formData?: HotelSearchCriteriaPropTypes,
        activeFilters: string[],
        filters?: HotelSearchResultsFiltersPropTypes
    }>({activeFilters: []});

    const {id} = useParams<{ id: string }>();

    const intId = parseInt(id || "", 10);
    const location = useLocation();

    useEffect(() => {
        setStateBackup();
        if (location.search) {
            handleQueryParams(intId);
        }

        if (intId && locale) {
            void getHotelDetails(intId, locale);
        }

        return () => {
            if (!submitedSearch || !submitedFilters) {
                void discardChanges();
            }
        };
    }, []);

    useEffect(() => {
        setHotelDetails(dataForFilters, intId, locale);
        setStateBackup();
    }, [dataForFilters, intId, locale]);

    const getHotelDetails = useCallback((id: number, locale: string) => {
        const onReceive = (hotelDetails: HotelDetails[]) => {
            if (!hotelDetails[0]?.hotel) {
                // invalid hotel id supplied, return to hotel search
                router.navigate("/hotels");
            }

            setHotel(hotelDetails[0]?.hotel);
        };
        getHotelsDetailsOnly([id], onReceive);
    }, [getHotelsDetailsOnly]);

    useEffect(() => {
        if (!hotel && location.search) {
            void getHotelDetails(intId, locale);
        }
    }, [getHotelDetails, hotel, intId, locale, location.search]);

    useEffect(() => getHotelDetails(intId, locale), [getHotelDetails, intId, locale]);

    const handleQueryParams = useCallback((id: number) => {
        checkQueryParams(id);
    }, [checkQueryParams]);

    useEffect(() => {
        // handleQueryParams(intId);
        void getHotelDetails(intId, locale);
    }, [getHotelDetails, handleQueryParams, intId, locale, location.search]);

    const setStateBackup = useCallback(() => {
        setBackup({
            formData,
            filters,
            activeFilters,
            nationalityInput
        });
    }, [activeFilters, filters, formData, nationalityInput]);

    const discardChanges = useCallback(() => {
        const {
            formData,
            filters,
            nationalityInput
        } = backup;

        if (formData) {
            handleFormData(formData);
        }

        if (filters) {
            handleFilters(filters);
        }

        handleInputChange("nationalityInput", nationalityInput);
        submitSearch();
        submitFilters();

        setSearchCriteriaChanged(false);
    }, [backup, handleFilters, handleFormData, handleInputChange, submitFilters, submitSearch]);

    const setHotelDetails = useCallback((dataForFilters: SimpleHotelOfferPropTypes[], id: number, locale: string) => {
        const {hotel: hotelRedux} = props;

        const hotelOffer = dataForFilters?.filter((e) => e.hotel.id === id);
        if (hotelOffer?.length > 0) {
            const {
                hotel
            } = hotelOffer[0];

            if (hotel) {
                setHotel(hotelRedux);
            } else {
                void getHotelDetails(id, locale);
            }
        }
    }, [getHotelDetails, props]);

    const getRoomOffers = useCallback((dataForFilters: SimpleHotelOfferPropTypes[], id: number): RoomOfferPropTypes[][] | undefined => {
        const hotelOffer = dataForFilters?.filter((e) => e.hotel.id === id);
        if (hotelOffer?.length > 0) {
            return hotelOffer[0].roomOffers;
        }

        return undefined;
    }, []);

    const getRoomDescriptions = useCallback((datahotelOffers:  HotelOfferPropTypes[], id: number): RoomDescriptionPropTypes[] | undefined => {
        const hotelOffer = datahotelOffers?.filter((e) => e.hotel.id === id);
        if (hotelOffer?.length > 0) {
            return hotelOffer[0].roomDescriptions;
        }

        return undefined;
    }, []);

    const handleSearchCriteriaChanged = useCallback(() => setSearchCriteriaChanged(true), []);

    const handleFullWidthMap = useCallback(() => setFullWidthMap(!fullWidthMap), [fullWidthMap]);

    const changeCenter = useCallback((newCenter: google.maps.LatLng) => {
        let equal;
        if (center && typeof center.lat === "function") {
            equal = center.lat() === newCenter.lat() && center.lng() === newCenter.lng();
        } else {
            equal = center === newCenter;
        }

        if (!equal) {
            setCenter(center);
        }
    }, [center]);

    const changeZoom = useCallback((newZoom: number) => {
        if (newZoom !== zoom) {
            setZoom(newZoom);
        }
    }, [zoom]);

    return (
        <>
            {hotel && (
                <Hotel
                    data={{
                        roomOffers: getRoomOffers(dataForFilters, intId),
                        roomDescriptions: getRoomDescriptions(datahotelOffers, intId),
                        hotel,
                        formData,
                        filters,
                        activeFilters,
                        searchError,
                        isSearching,
                        isActivePinnedOffers,
                        mapTypeId,
                        center,
                        zoom,
                        searchCriteriaChanged,
                        fullWidthMap,
                        error,
                        backup
                    }}
                    handleSearchCriteriaChanged={handleSearchCriteriaChanged}
                    handleFullWidthMap={handleFullWidthMap}
                    changeCenter={changeCenter}
                    changeZoom={changeZoom}
                    discardChanges={discardChanges}
                    changeMapTypeId={changeMapTypeId}
                />
            )}
            {!hotel && (<Spinner size={50}/>)}
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    locale: state.locale.currentLocale,
    formData: state.hotelSearch.formData,
    searchError: state.hotelSearch.error,
    submitedSearch: state.hotelSearch.submitedSearch,
    nationalityInput: state.hotelSearch.nationalityInput,
    isSearching: state.hotelSearch.isSearching,
    dataForFilters: state.hotelSearchResults.dataForFilters,
    datahotelOffers: state.hotelSearchResults?.hotelOffers,
    submitedFilters: state.hotelSearchResults.submitedFilters,
    filters: state.hotelSearchResults?.filters,
    activeFilters: state.hotelSearchResults.activeFilters,
    pinnedOffers: state.pinnedOffers,
    hotel: state.hotelSearchResults.hotelOffers[0]?.hotel,
    mapTypeId: state.map.mapTypeId
});

const connector = connect(mapStateToProps, {
    handleFilters,
    handleFormData,
    handleInputChange,
    submitSearch,
    submitFilters,
    logout,
    checkQueryParams,
    getHotelsDetailsOnly,
    changeMapTypeId
});

export default withPinnedOffers(connector(HotelContainer));
